define(['app','siteObj'], (app, siteObj) => {
  const logoutButton = () => {
    const component = {};

    const _config = {
      selectors: {
        logoutButton: '.myAccount_logOutButton',
        footerLogoutButton: '.myAccount_footer_logOutButton'
      }
    }


    const _init = (element) => {
      component.element = element;
      component.logoutButton = document.querySelector(component.config.selectors.logoutButton);
      component.footerLogoutButton = document.querySelector(component.config.selectors.footerLogoutButton);

      component.bind();
    };

    const _bind = () => {
      if (document.querySelector(component.config.selectors.logoutButton)) {
        component.logoutButton.addEventListener('click', () => component.logOutEvent());
      }
      if (document.querySelector(component.config.selectors.footerLogoutButton)) {
        component.footerLogoutButton.addEventListener('click', () => component.logOutEvent());
      }
    };

    const _logOutEvent = ()  => {
      window.dataLayer.push({
        event: "elysiumEvent",
        eventData: {
          eventCategory: "logout",
        }
      })
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.logOutEvent = _logOutEvent;
    return component;
  };
  return logoutButton;
});
